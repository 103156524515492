import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import type { ConsentChange } from '@fortum/global-consent-components'

import { DeliveryMethodOptions } from '@/open-web/components/Checkout/contants'

import { ADDRESS_DEFAULTS } from '../defaults'
import { middleware } from '../middleware'
import type { Address, Store } from '../types'

//Payload Types
type InvoiceMethod = 'e-invoice' | 'post' | 'email'
type ContractInfoDeliveryMethod = keyof typeof DeliveryMethodOptions

type MeterInformationSource = 'poa' | 'manualInput'

export type CheckoutDataState = {
  consents: ConsentChange[]
  delivery: {
    startDate?: string
  }
  billing: {
    method: InvoiceMethod
    address?: Address
    bankCode?: string
  }
  meter: {
    address: Address
    meterInformationSource?: MeterInformationSource
    gridOwnerCode?: string
    meterPointId?: string
  }
  contractInfoDeliveryMethod?: ContractInfoDeliveryMethod
}

type CheckoutDataActions = {
  setConsents: (consents: ConsentChange[]) => void
  setMeter: (meter: CheckoutDataState['meter']) => void
  setMeterInformationSource: (meterInformationSource: MeterInformationSource) => void
  setStartDeliveryDate: (startDate?: string) => void
  setBillingAddress: (address?: Address) => void
  setBillingBankCode: (bankCode?: string) => void
  setBillingMethod: (method: InvoiceMethod) => void
  setContractInfoDeliveryMethod: (method: ContractInfoDeliveryMethod) => void
}

export type CheckoutDataStore = {
  checkoutData: CheckoutDataState & CheckoutDataActions
}

export const initialState: CheckoutDataState = {
  consents: [],
  delivery: {},
  billing: {
    address: ADDRESS_DEFAULTS,
    method: 'e-invoice',
  },
  meter: {
    address: ADDRESS_DEFAULTS,
    meterInformationSource: undefined,
    gridOwnerCode: undefined,
    meterPointId: undefined,
  },
  contractInfoDeliveryMethod: DeliveryMethodOptions.DIGITAL,
}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], CheckoutDataStore> = (
  set,
  get,
) => ({
  checkoutData: {
    ...initialState,
    setConsents: (consents) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.consents = consents
        }),
      ),
    setMeter: (meter) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.meter = meter
        }),
      ),
    setMeterInformationSource: (meterInformationSource) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.meter.meterInformationSource = meterInformationSource
        }),
      ),
    setStartDeliveryDate: (startDate) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.delivery = { startDate }
        }),
      ),
    setBillingAddress: (address) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing = { ...state.checkoutData.billing, address }
        }),
      ),
    setBillingBankCode: (bankCode) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing = { ...state.checkoutData.billing, bankCode }
        }),
      ),
    setBillingMethod: (method) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.billing = { ...state.checkoutData.billing, method }
        }),
      ),
    setContractInfoDeliveryMethod: (method) =>
      set(
        produce(get(), (state: Store) => {
          state.checkoutData.contractInfoDeliveryMethod = method
        }),
      ),
  },
})

export const createCheckoutDataSlice = middleware(creator, (_prevState, _set, _get, _store) => {})
