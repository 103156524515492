import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import { logError } from '@/shared/utils/error'
import { BaseError } from '@/shared/utils/errorClasses'

import { middleware } from '../middleware'
import type { Store } from '../types'

type ErrorActions = {
  addError: (error: BaseError) => void
  removeErrorById: (id: string) => void
  removeErrorsByType: (type: string) => void
  clearErrors: () => void
}

type ErrorState = {
  errorList: BaseError[]
}

export type ErrorStore = {
  errors: ErrorState & ErrorActions
}

const initialState: BaseError[] = []

const creator: StateCreator<Store, [['zustand/persist', unknown]], [], ErrorStore> = (
  set,
  get,
) => ({
  errors: {
    errorList: initialState,
    addError: (error) => {
      if (error instanceof BaseError) {
        logError(error)
      }
      set(
        produce(get(), (state: Store) => {
          state.errors.errorList.push(error)
        }),
      )
    },
    removeErrorById: (id) =>
      set(
        produce(get(), (state: Store) => {
          state.errors.errorList = state.errors.errorList.filter((error) => error.id !== id)
        }),
      ),
    removeErrorsByType: (type) =>
      set(
        produce(get(), (state: Store) => {
          state.errors.errorList = state.errors.errorList.filter((error) => error.code !== type)
        }),
      ),
    clearErrors: () =>
      set(
        produce(get(), (state: Store) => {
          state.errors.errorList = []
        }),
      ),
  },
})

export const createErrorSlice = middleware(creator, (_prevState, _set, _get, _store) => {})
