import { browserEnvs } from '@/shared/envs'

//For complete input validations
export const FULL_POSTAL_CODE_REGEX = /^\d{3}\s?\d{2}$/

//To validate data of Postal Code during input
export const getPostalCodeRegex = (): RegExp => {
  switch (browserEnvs.NEXT_PUBLIC_COUNTRY) {
    case 'SE':
    case 'FI':
    case 'NO':
    case 'GB':
    default:
      return /^[\d ]{0,6}$/
  }
}
