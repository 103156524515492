import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import type { Answer, QuestionCollection } from '@/open-web/components/Quiz/types'
import { browserEnvs } from '@/shared/envs'

import { middleware } from '../middleware'
import type { Store } from '../types'

export type QuizFlowState = {
  // All questions in the quiz and they should include the answers. Question is identified by the question id key
  questions: QuestionCollection
  // Current displayed question id
  currentQuestionId: string | null
  // Selected answer Ids is pushed to this array
  selectedAnswers: Answer[]
  // If the answer does not have a nextQuestion, the quiz is complete
  isQuizComplete: boolean

  // This is mainly used for tracking purposes
  questionIndex: number
}

export type QuizFlowActions = {
  setQuestions: (questions: QuestionCollection) => void
  setCurrentQuestionId: (id: string) => void
  onPreviousQuestion: () => void
  onAnswerSelected: (answer: Answer) => void
  onQuizStarted: () => void
  resetIsQuizComplete: () => void
  resetQuizFlow: () => void
  setQuestionIndex: (index: number) => void
}

export const initialState: QuizFlowState = {
  questions: {},
  currentQuestionId: null,
  selectedAnswers: [],
  isQuizComplete: false,

  // Default index is one as it started from welcome page
  questionIndex: 1,
}

export type QuizFlowStore = {
  quizFlow: QuizFlowState & QuizFlowActions
}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], QuizFlowStore> = (
  set,
  get,
) => ({
  quizFlow: {
    ...initialState,

    resetQuizFlow: () =>
      set(
        produce(get(), (state: Store) => {
          state.quizFlow = { ...initialState, ...state.quizFlow }
          state.quizFlow.questionIndex = 1
        }),
      ),

    setQuestionIndex: (index: number) => {
      set(
        produce(get(), (state: Store) => {
          state.quizFlow.questionIndex = index
        }),
      )
    },

    onQuizStarted: () =>
      set(
        produce(get(), (state: Store) => {
          state.quizFlow.currentQuestionId = Object.keys(state.quizFlow.questions)[0]
          state.quizFlow.questionIndex = state.quizFlow.questionIndex + 1
        }),
      ),

    setQuestions: (questions) =>
      set(
        produce(get(), (state: Store) => {
          state.quizFlow.questions = questions
        }),
      ),

    setCurrentQuestionId: (id) =>
      set(
        produce(get(), (state: Store) => {
          state.quizFlow.currentQuestionId = id
        }),
      ),

    resetIsQuizComplete: () => {
      set(
        produce(get(), (state: Store) => {
          state.quizFlow.isQuizComplete = false
          state.quizFlow.questionIndex = 1
        }),
      )
    },

    onPreviousQuestion: () =>
      set(
        produce(get(), (state: Store) => {
          if (state.quizFlow.isQuizComplete && browserEnvs.NEXT_PUBLIC_COUNTRY === 'SE') {
            state.quizFlow.isQuizComplete = false
            return
          }

          state.quizFlow.questionIndex = state.quizFlow.questionIndex - 1

          if (state.quizFlow.isQuizComplete) {
            state.quizFlow.isQuizComplete = false
          }

          // If there are no selected answers, we are at the first question
          if (state.quizFlow.selectedAnswers.length === 0 && state.quizFlow.currentQuestionId) {
            state.quizFlow.currentQuestionId = null
          }

          // If there are selected answers, we go back to the previous question
          if (state.quizFlow.selectedAnswers.length > 0) {
            const lastAnswer = state.quizFlow.selectedAnswers.at(-1)

            if (lastAnswer) {
              state.quizFlow.currentQuestionId = lastAnswer.questionId
            }

            state.quizFlow.selectedAnswers = state.quizFlow.selectedAnswers.slice(0, -1)
          }
        }),
      ),

    onAnswerSelected: (answer) =>
      set(
        produce(get(), (state: Store) => {
          if (state.quizFlow.isQuizComplete) {
            return
          }

          if (answer?.nextQuestionId) {
            state.quizFlow.currentQuestionId = answer.nextQuestionId
            state.quizFlow.selectedAnswers = [...state.quizFlow.selectedAnswers, answer]
            state.quizFlow.questionIndex = state.quizFlow.questionIndex + 1
          } else {
            state.quizFlow.isQuizComplete = true
          }
        }),
      ),
  },
})

export const createQuizFlowSlice = middleware(creator, (_prevState, _set, _get, _store) => {})
